import React from 'react'
import styled from '@emotion/styled'

const Footer = () => (
  <StyledFooter>
    © {new Date().getFullYear()}, Built with <a href="https://www.gatsbyjs.org">Gatsby</a> by Me.
    Original design by <a href="https://daleanthony.com/">Dale Anthony.</a>
  </StyledFooter>
)

const StyledFooter = styled.footer`
  margin: 1rem 0;
  border-top: 2px solid var(--footer-border-color);
  color: var(--footer-text-color);
  font-size: .8em;
  padding: 1rem 0;

  a {
    color: var(--footer-text-color);
  }
`

export default Footer
