/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location, prevLocation }) => {
  // hack fix for scroll position not being updated after loading a new page
  document.getElementById('content').scrollTo(0, 0)
}
