import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Footer from '../components/footer'
import Sidebar from '../components/sidebar'
import SidebarImage from '../components/sidebarImage'

import '@fortawesome/fontawesome-svg-core/styles.css'
import './reset.css'
import './theme.css'

const Layout = ({ children }) => (
  <Page>
    <SidebarContainer>
      <SidebarImage />
      <Sidebar />
    </SidebarContainer>
    <Content id='content'>
      <ContentContainer>
        {children}
      </ContentContainer>
      <Footer />
    </Content>
  </Page>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const ContentContainer = styled.div`

`

const Page = styled.div`
  display: flex;
  height: 100vh;
`

// TODO: hide sidebar on mobile
const SidebarContainer = styled.div`
  flex: 0 0 405px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #10111e;

  @media (max-width: 1200px) {
    display: none;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 2rem 7rem;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    padding: 1.6rem;
  }
`

export default Layout
