import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Avatar from './avatar'

library.add(faGithub, faTwitter, faLinkedin, faInstagram)

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title, description, twitter, linkedIn, gitHub, instagram
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Avatar />
      <SiteTitle>
        <Link to="/">
          {data.site.siteMetadata.title}
        </Link>
      </SiteTitle>
      <SiteSlogan>
        {data.site.siteMetadata.description}
      </SiteSlogan>
      <p>
        <Link to="/">
          Home
        </Link>
      </p>

      <SocialLinks>
        <SocialLink href={data.site.siteMetadata.twitter}>
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </SocialLink>
        <SocialLink href={data.site.siteMetadata.linkedIn}>
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </SocialLink>
        <SocialLink href={data.site.siteMetadata.gitHub}>
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </SocialLink>
        <SocialLink href={data.site.siteMetadata.instagram}>
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </SocialLink>
      </SocialLinks>
    </Wrapper>
  )
}

const SocialLinks = styled.div`
`

const SocialLink = styled.a`
  font-size: 3.6rem;
`

const Wrapper = styled.div`
  position: absolute;
  text-align: center;
  color: var(--inverted-text-color);

  a {
    text-decoration: none;
    color: var(--inverted-text-color);
    padding: .5rem 1.25rem;
    font-size: .9em;
    font-weight: bold;
    letter-spacing: 1px;
  }

  a:hover {
    color: var(--highlight-color-dark);
    transition: all 350ms;
  }
`

const SiteTitle = styled.div`
  margin: 10px auto;
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: .05rem;
`

const SiteSlogan = styled.div`
  margin: 10px 2rem;
  font-size: .9rem;
  line-height: 1.75rem;
`

export default Sidebar
