import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Avatar = () => {
  const data = useStaticQuery(graphql`
  query LoadProfilePic {
    allImageSharp(filter: {resize: {originalName: {eq: "profile_pic.jpg" } } }) {
      edges {
        node {
          fixed(width: 127) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }`)

  return <StyledImg fixed={data.allImageSharp.edges[0].node.fixed} />
}

const StyledImg = styled(Img)`
  width: 127px;
  height: 127px;
  border-radius: 50%;
  margin: 0 auto;
`

export default Avatar
